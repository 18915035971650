@import '~bootstrap/dist/css/bootstrap.min.css';
@import '~bootstrap-icons/font/bootstrap-icons.min.css';

body {
    background: rgb(46, 63, 87);
    background: linear-gradient(
      90deg,
      rgb(63, 86, 119) 0%,
      rgba(33, 44, 61, 1) 42%,
      rgb(15, 21, 29) 100%
    );
  }
  
  /*SIDEBAR SECTION*/
  .navbar.fixed-left {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 230px;
    flex-flow: column nowrap;
    align-items: flex-start;
    background-color: black;
    z-index: 1200;
  }
  
  .navbar.fixed-left .navbar-collapse .navbar-nav a {
    color: rgb(145, 145, 145) !important;
    transition: 0.3s;
  }
  
  .navbar.fixed-left .navbar-collapse .navbar-nav a:hover {
    color: white !important;
  }
  
  .navbar.fixed-left .navbar-brand {
    padding-top: 20px;
    padding-bottom: 30px;
    padding-left: 10px;
  }
  
  ul {
    list-style-type: none;
    padding-left: 3px;
  }
  
  .nav-btn {
    margin-bottom: 20px;
    text-align: center;
  }
  
  .nav-btn a {
    color: rgb(145, 145, 145);
  }
  
  .signup-btn {
    background-color: white;
    color: black;
    border: 1px solid white;
    border-radius: 20px;
    width: 180px;
    margin-bottom: 20px;
  }
  
  .signup-btn:hover {
    color: white;
    background-color: rgb(60, 211, 60);
    border: 1px solid rgb(60, 211, 60);
  }

  .logout-btn {
    background-color: rgb(246, 32, 32);
    color: black;
    border-radius: 20px;
    width: 180px;
    margin-bottom: 20px;
    margin-top: 20px;
  }
  
  .logout-btn:hover {
    color:#282828;
    background-color: rgb(214, 208, 28);
  }

  
  .login-btn {
    background-color: black;
    color: white;
    border: 1px solid white;
    border-radius: 20px;
    width: 180px;
    margin-bottom: 20px;
  }
  
  .login-btn:hover {
    color: black;
    background-color: white;
  }
  
  @media screen and (max-width: 936px) {
    #sidebar {
      display: none;
    }
  }
  
  /* BOTTOM PLAYER SECTION*/
  
  .bg-container {
    height: 5.5rem;
    background-color: #282828;
  }
  
  .playerControls .row {
    justify-content: space-between;
  }
  
  .playerControls a img {
    width: 14px;
    margin-top: 1rem;
    filter: brightness(60%);
  }
  
  .playerControls a:hover {
    filter: brightness(80%);
    color: #282828 !important;
  }
  
  .progress {
    color: white;
    background-color: #414141;
    height: 3px;
  }
  
  .playBar p {
    margin-bottom: 20px;
    color: white;
  }
  
  /*MAIN PAGE SECTION*/
  
  .mainLinks {
    margin-top: 1.5rem;
    justify-content: space-evenly;
  }
  
  .mainLinks a {
    color: white;
    font-weight: 700;
    font-size: 12px;
    opacity: 0.3;
  }
  
  .mainLinks a:hover {
    text-decoration: none;
    opacity: 1;
    transition: 0.3s;
  }
  
  .imgLinks a p,
  .mainPage p a {
    padding-top: 8px;
    font-size: 12px;
    color: white;
    font-weight: 700;
  }
  
  .mainPage p a:hover {
    color: rgb(138, 138, 138);
  }
  
  .imgLinks a:hover {
    text-decoration: none;
  }
  
  .imgLinks a img:hover {
    filter: brightness(50%);
  }
  
  #rock,
  #pop,
  #hiphop,
  #searchResults,
  #artist-tracks {
    color: white;
    font-weight: 700;
    padding-top: 50px;
  }
  
  .trackHover:hover {
    background-color: black;
    cursor: pointer;
    border-radius: 0.375rem;
  }
  
  .trackHover a {
    text-decoration: none;
  }

  .trackHover a {
    color: #dfd9d9;
  }
  
  #btnPlay {
    font-weight: 700;
    width: 150px;
    height: 50px;
    border-radius: 50px;
  }
  
  .duration {
    float: right;
  }
  
  .mainButton {
    border: 1px solid #fff !important;
    border-radius: 20px !important;
    height: 2.2em !important;
    width: 7em;
    text-align: center;
    padding: 0px 10px !important;
  }
  
  .titleMain {
    color: white;
    text-align: center;
    font-weight: 700;
    text-transform: uppercase;
  }
  
  #hipHopSection {
    margin-bottom: 7rem;
  }
  
  #followers {
    color: white;
    text-align: center;
    margin-bottom: 2rem;
  }
  
  .album-title {
    color: white;
    font-weight: bold;
    font-size: x-large;
    margin-bottom: 0;
  }
  
  .artist-name {
    color: white;
  }
  
  .mainPage img {
    width: 200px;
  }

  a {
    text-decoration: none;
  }
  
  .card {
    background-color: transparent!important;
    border-width: 0;
    display: flex;
    align-items: center;
  }

  .artist-name, .artist {
    cursor: pointer;
    text-decoration: underline;
  }

  .artist {
    color: #a2a0a0;
  }

.logograndespotify {
  display: block;
  margin: 0 auto;
}

.risultati h2 {
  color: whitesmoke;
}

.risultatiCopertine {
  border-radius: 1em;
}

#searchField {
  border-radius: 20px;
}

.rhap_container {
  background-color: #282828!important;
  padding: 0!important;
}

.rhap_time {
  color: #e1dbdb!important;
}

.col-md-2.offset-md-2.text-white.text-center, .col-md-2.offset-md-2.text-white.text-center.artist, .col-md-2.offset-md-1.text-white.d-none.d-lg-block {
  align-self: center;
  display: flow-root;
}

.nomeSearchQuery {
  font-size: 2em;
  color: rgb(47,213,102);
  padding-left: 0.5em;
}

.risultati h2 {
  margin-bottom: 1.3em;
}

a strong {
  color:rgb(47,213,102);
  font-size: 1.5em;
}

#rockFailure {
  margin-top: 2em;
  color: white;
  font-weight: 700;
  padding-top: 50px;
}


#rockFailure h1 strong, #rock h2 strong {
  color:rgb(47,213,102);
  font-size: 1.3em;
}

.bi.bi-heart.cuore.mx-3 {
  font-size: 1.8em;
  color: white;
  cursor: pointer;
  
}

.bi.bi-heart.cuoreSmall.mx-3 {
  font-size: 1.2em;
  cursor: pointer;
}

.mt-4.text-center {
  display: flex;
  justify-content: center;
  align-items: center; 
}

.imgPiccolaPlayer {
  width: 4em;
}

.bi.bi-heart-fill.cuore.mx-3 {
  font-size: 1.8em;
  color: red;
  cursor: pointer;
}

.copyleft {
  display:inline-block;
  transform: rotate(180deg);
}